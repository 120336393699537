<template>
  <div class="news">
    <van-nav-bar
      :title="$t('Menu.signal')"
      :placeholder="true"
      fixed
      z-index="999"
      :border="false"
    >
      <!-- <template #right>
        <van-icon name="more-o" color="#fff" size="18" />
      </template> -->
    </van-nav-bar>
    <van-tabs
      v-model="active"
      animated
      swipeable
      color="#13b5b1"
      @change="change"
    >
      <van-tab :title="$t('cooperationsignal')" name="hexh">
        <Message ref="hexh"></Message>
      </van-tab>
      <van-tab :title="$t('freesignal')" name="jhxh">
        <Notify ref="jhxh"></Notify>
      </van-tab>
    </van-tabs>
  </div>
</template>

<script>
import Message from "@/views/community/Message";
import Notify from "@/views/community/Notify";
export default {
  name: "index",
  data() {
    return {
      active: 0,
    };
  },
  components: {
    Message,
    Notify,
  },
  mounted() {},
  watch: {},
  created() {
    this.loaddata(); // 如果为true。那么调用设置树形默认值的方法
  },
  methods: {
    loaddata() {
      this.$nextTick(() => {
        this.$refs.hexh.loadPageData();
      });
    },
    change(name, title) {
      switch (name) {
        case "jhxh":
          this.$nextTick(() => {
            this.$refs.jhxh.loadPageData();
          });
          break;
        case "hexh":
          this.$refs.hexh.loadPageData();
          break;
      }
    },
  },
};
</script>

<style scoped>
.news {
  overflow-y: auto;
  background-color: #f7f8fa;
}
.van-nav-bar__placeholder {
  height: 66px;
}
.news >>> .van-nav-bar {
  background-color: #13b5b1;
  padding: 20px 0 0 0;
}
.news >>> .van-nav-bar__title {
  color: #fff;
}
.van-tabs {
  padding-top: 12px;
}
</style>
