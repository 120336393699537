<template>
  <div>
    <div class="box">
      <van-dropdown-menu active-color="#25a4bb">
        <van-dropdown-item get-container="body" :title="$t('sort')" ref="item">
          <van-cell center :title="$t('AllProfit')" @click="Sort('AllProfit')">
            <template #right-icon>
              <i
                :class="{
                  fa: true,
                  'fa-long-arrow-down': OrderType == 'desc',
                  'fa-long-arrow-up': OrderType == 'asc',
                }"
                aria-hidden="true"
                :style="{
                  display: OrderName == 'AllProfit' ? 'block' : 'none',
                }"
              ></i>
            </template>
          </van-cell>
          <van-cell
            center
            :title="$t('WeekProfit')"
            @click="Sort('WeekProfit')"
          >
            <template #right-icon>
              <i
                :class="{
                  fa: true,
                  'fa-long-arrow-down': OrderType == 'desc',
                  'fa-long-arrow-up': OrderType == 'asc',
                }"
                aria-hidden="true"
                :style="{
                  display: OrderName == 'WeekProfit' ? 'block' : 'none',
                }"
              ></i>
            </template>
          </van-cell>
          <van-cell
            center
            :title="$t('MonthProfit')"
            @click="Sort('MonthProfit')"
          >
            <template #right-icon>
              <i
                :class="{
                  fa: true,
                  'fa-long-arrow-down': OrderType == 'desc',
                  'fa-long-arrow-up': OrderType == 'asc',
                }"
                aria-hidden="true"
                :style="{
                  display: OrderName == 'MonthProfit' ? 'block' : 'none',
                }"
              ></i>
            </template>
          </van-cell>
          <van-cell
            center
            :title="$t('ProfitPercent')"
            @click="Sort('ProfitPercent')"
          >
            <template #right-icon>
              <i
                :class="{
                  fa: true,
                  'fa-long-arrow-down': OrderType == 'desc',
                  'fa-long-arrow-up': OrderType == 'asc',
                }"
                aria-hidden="true"
                :style="{
                  display: OrderName == 'ProfitPercent' ? 'block' : 'none',
                }"
              ></i>
            </template>
          </van-cell>
          <van-cell center :title="$t('Name')" @click="Sort('Name')">
            <template #right-icon>
              <i
                :class="{
                  fa: true,
                  'fa-long-arrow-down': OrderType == 'desc',
                  'fa-long-arrow-up': OrderType == 'asc',
                }"
                aria-hidden="true"
                :style="{ display: OrderName == 'Name' ? 'block' : 'none' }"
              ></i>
            </template>
          </van-cell>
          <van-cell
            center
            :title="$t('CreateTime')"
            @click="Sort('CreateTime')"
          >
            <template #right-icon>
              <i
                :class="{
                  fa: true,
                  'fa-long-arrow-down': OrderType == 'desc',
                  'fa-long-arrow-up': OrderType == 'asc',
                }"
                :style="{
                  display: OrderName == 'CreateTime' ? 'block' : 'none',
                }"
                aria-hidden="true"
              ></i>
            </template>
          </van-cell>
        </van-dropdown-item>
      </van-dropdown-menu>
      <van-cell-group>
        <van-cell
          v-for="(item, index) in records"
          :key="index"
          :title="item.Name"
          icon="user-circle-o"
          :label="item.Balance.toFixed(2)"
          @click="goTrent(item)"
        >
          <template #default>
            <div style="color: darkgray">
              {{ item.CreateTime.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "") }}
            </div>
            <div>
              <span :style="{ color: item.AllProfit > 0 ? 'green' : 'red' }">{{
                item.AllProfit.toFixed(2)
              }}</span>
            </div>
          </template>
        </van-cell>
      </van-cell-group>
      <!-- <van-collapse v-model="activeNames">
        <van-collapse-item v-for="(item, index) in records" :key="index">
          <template #title>
            <div>
              <strong>{{ item.Name }}</strong>
            </div>
            <div>{{ item.Balance.toFixed(2) }}</div>
          </template>
          <template #right-icon>
            <div>
              <div style="color: darkgray">
                {{
                  item.CreateTime.replace(/T/g, " ").replace(/\.[\d]{3}Z/, "")
                }}
              </div>
              <div style="float: right">
                <span
                  :style="{ color: item.AllProfit > 0 ? 'green' : 'red' }"
                  >{{ item.AllProfit.toFixed(2) }}</span
                >
              </div>
            </div>
          </template>
          <van-row>
            <van-col span="24" style="margin-bottom: 15px">
              <depositecharts
                :xdata="formatxdata(item.AllDeposit)"
                :ydata="formatydata(item.AllDeposit)"
                :title="$t('banlancetrend')"
              ></depositecharts>
            </van-col>
            <van-col span="24" style="color: #34495e"
              >{{ $t("signaldetail") }}<span class="itemright"></span
            ></van-col>
            <van-col span="12"
              >{{ $t("Balance") }}
              <span class="itemright">{{ item.Balance }}$</span></van-col
            >
            <van-col span="12"
              >{{ $t("Equity") }}
              <span class="itemright">{{ item.Equity }}$</span></van-col
            >
            <van-col span="12"
              >{{ $t("Deposit") }}
              <span class="itemright">{{ item.Deposit }}$</span></van-col
            >
            <van-col span="12"
              >{{ $t("Withdrawal") }}
              <span class="itemright">{{ item.Withdrawal }}$</span></van-col
            >
            <van-col span="12"
              >{{ $t("AllProfit") }}
              <span class="itemright">{{ item.Profit1 }}$</span></van-col
            >
            <van-col span="12"
              >{{ $t("Profit2") }}
              <span class="itemright">-{{ item.Profit2 }}$</span></van-col
            >
            <van-col span="24"
              >{{ $t("Profit") }}
              <span class="itemright">{{ item.Profit }}$</span></van-col
            >
            <van-col span="12"
              >{{ $t("ProfitPercent") }}
              <span class="itemright">{{
                item.ProfitPercent.toFixed(2)
              }}</span></van-col
            >
            <van-col span="12"
              >{{ $t("Volume") }}
              <span class="itemright">{{ item.Volume }}</span></van-col
            >
            <van-col span="12"
              >{{ $t("TicketCount") }}
              <span class="itemright">{{ item.TicketCount }}</span></van-col
            >
            <van-col span="12"
              >{{ $t("TicketBuy") }}
              <span class="itemright">{{ item.TicketBuy }}</span></van-col
            >
            <van-col span="12"
              >{{ $t("TicketSell") }}
              <span class="itemright">{{ item.TicketSell }}</span></van-col
            >
            <van-col span="12"
              >{{ $t("ProfitCount1") }}
              <span class="itemright">{{ item.ProfitCount1 }}</span></van-col
            >
            <van-col span="12"
              >{{ $t("ProfitCount2") }}
              <span class="itemright">{{ item.ProfitCount2 }}</span></van-col
            >

            <van-col span="24" style="color: #34495e"
              >{{ $t("tradesummary") }} <span class="itemright"></span
            ></van-col>
            <van-col span="12"
              >{{ $t("TradeNum") }}
              <span class="itemright">{{ item.TradeNum }}</span></van-col
            >
            <van-col span="12"
              >{{ $t("TradeCycle") }}
              <span class="itemright">{{ item.TradeCycle }}</span></van-col
            >
            <van-col span="12"
              >{{ $t("MaxDrawdown") }}
              <span class="itemright"
                >{{ item.MaxDrawdown.toFixed(2) * 100 }}%</span
              ></van-col
            >
            <van-col span="12"
              >{{ $t("WeekProfit") }}
              <span class="itemright"
                >{{ item.WeekProfit.toFixed(2) }}$</span
              ></van-col
            >
            <van-col span="12"
              >{{ $t("MonthProfit") }}
              <span class="itemright"
                >{{ item.MonthProfit.toFixed(2) }}$</span
              ></van-col
            >
            <van-col span="12"
              >{{ $t("sumProfit") }}
              <span class="itemright"
                >{{ item.AllProfit.toFixed(2) }}$</span
              ></van-col
            >
            <van-col span="24" style="display: none">
              <van-notice-bar
                wrapable
                :scrollable="false"
                :text="$t('netprofitincludefee')"
              />
            </van-col>
            <van-col span="24" style="padding: 15px 0">
              <van-button
                round
                block
                type="info"
                size="small"
                @click="copy(item.ID)"
                >{{ $t("Copy") }}</van-button
              >
            </van-col>
          </van-row>
        </van-collapse-item>
      </van-collapse> -->
      <Loading v-if="showLoading"></Loading>
      <div style="margin-bottom: 0px; text-align: center; padding: 25px 0px">
        <span v-if="records.length < total" @click="nextpage()">{{
          $t("app.clickLoadMore")
        }}</span>
        <span v-else>{{ $t("app.noMoreData") }}</span>
      </div>
    </div>
  </div>
</template>

<script>
    import { GetVWSignalPages } from "@/api";
    import { Notify, Dialog } from 'vant';
import depositecharts from "@/components/depositecharts";
export default {
  name: "hezuoxinhao",
  components: { 
    depositecharts,
    Loading: () => import("@/components/crm/Loading"),
  },
  data() {
    return {
      pageIndex: 1,
      pageSize: 20,
      records: [],
      total: 0,
      value: 0,
      color: "green",
      option: [{ text: "全部账号", value: 0 }],
      activeNames: [],
      OrderName: "AllProfit",
      OrderType: "desc",
      Search: "",
      showLoading: true,
    };
  },
  mounted() {},
  watch: {},
  created() {},
  methods: {
    goTrent(data) {
      this.$router.push({
        name: "Trent",
        params: {
          data: data,
        },
      });
    },
    loadPageData() {
      GetVWSignalPages({
        offset: this.pageIndex,
        pageSize: this.pageSize,
        dateTime: "1970-01-01—9999-12-12",
        orderName: this.OrderName,
        order: this.OrderType,
        search: this.Search,
        type: 2,
      }).then((res) => {
        if ((res.code = 200)) {
          this.showLoading = false;
          if (this.pageIndex == 1) {
            this.records = res.msg.rows;
          } else {
            res.msg.rows.forEach((element) => {
              this.records.push(element);
            });
          }
          this.total = res.msg.total;
        } else {
            Notify({ type: 'danger', message: this.$t(res.msg) });
        }
      });
    },
    nextpage() {
      this.pageIndex++;
      this.loadPageData();
    },
    copy(id) {
      this.$router.push({
        name: "/signal/applycopy",
        query: {
          ID: id,
        },
      });
    },
    Sort(value) {
      // debugger
      this.$refs.item.toggle();
      this.OrderName = value;
      if (this.OrderType == "desc") {
        this.OrderType = "asc";
      } else {
        this.OrderType = "desc";
      }
      this.pageIndex = 1;
      this.loadPageData();
    },
    fliter(value) {
      this.Search = value;
      this.pageIndex = 1;
      this.loadPageData();
    },
    formatxdata(AllDeposit) {
      //console.log("x");
      //console.log(AllDeposit);
      let xdata = new Array();
      if (AllDeposit == null) {
        return xdata;
      }

      var array = AllDeposit.split(",");
      array.forEach((item, index) => {
        var subarray = item.split(":");
        subarray.forEach((element, i) => {
          if (i == 0) xdata.push("");
        });
      });
      return xdata;
    },
    formatydata(AllDeposit) {
      let ydata = new Array();

      if (AllDeposit == null) {
        return ydata;
      }
      var array = AllDeposit.split(",");
      //console.log("y"); console.log(AllDeposit);
      array.forEach((item, index) => {
        var subarray = item.split(":");
        subarray.forEach((element, i) => {
          if (i == 1) ydata.push(parseFloat(element));
        });
      });
      return ydata;
    },
  },
};
</script>

<style scoped>
.box {
  overflow-y: auto;
}
.fa {
  color: #25a4bb;
}
.dropdown-menu-option-active-color {
  color: #25a4bb;
}
.itemright {
  float: right;
  padding-right: 10px;
}
</style>
